<template>
    <div v-if="actesTypes.length">
		<CustomTable
			v-if="(!isReproTab || !hasSeasonAccess) && (!isPerfsTab || !hasTrainerAccess)"
			ref="table"
			:id_table="id_table"
			:transformer="['ActeTransformer', 'withHorseAndMainResidenceNotes']"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			primaryKey="actes_id"
			:base-filters="filters"
			:date-filter="dateFilter"
			:externSlotColumns="['notes.type_fonts']"
			:buttonActionEventOnly="true"
			:persistDateFilter="true"
			:dateRangeV2="true"
		>
			<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
				<div 
					v-if="data.notes && data.notes.raw.length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"
				>
					<font-awesome-icon :icon="['fas', 'eye']" />
				</div>
			</template>
		</CustomTable>

		<CustomTable
			v-if="isReproTab && hasSeasonAccess"
			ref="table_repro"
			id_table="planning"
			:transformer="['ActeTransformer', 'withHorseAndMainResidence']"
			:busy.sync="table_busy"
			primaryKey="actes_id"
			:base-filters="filtersGyneco"
			:date-filter="dateFilter"
			:previewColumn="catchColumnShutter"
			:customPreviewColumn="column_previewable"
			:buttonActionEventOnly="true"
			:persistDateFilter="true"
			:dateRangeV2="true"
			:externSlotColumns="['notes.type_fonts']"
		>
			<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
				<div 
					v-if="data.notes && data.notes.raw.length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"
				>
					<font-awesome-icon :icon="['fas', 'eye']" />
				</div>
			</template>
		</CustomTable>
		<CustomTable
			v-if="isPerfsTab && hasTrainerAccess"
			ref="table_perf"
			id_table="liste_actes_performance"
			:transformer="['ActeTransformer', 'withHorseAndMainResidence']"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			primaryKey="actes_id"
			:base-filters="filters"
			:date-filter="dateFilter"
			:persistDateFilter="true"
			:dateRangeV2="true"
			:externSlotColumns="['notes.type_fonts']"
		>
			<template v-slot:[`custom-slot-cell(notes.type_fonts)`]="{ data }">
				<div 
					v-if="data.notes && data.notes.raw.length > 0" 
					class="previewColIdentifier"
					@click="catchColumnNotesShutter(data)"
				>
					<font-awesome-icon :icon="['fas', 'eye']" />
				</div>
			</template>
		</CustomTable>
    </div>
</template>

<script>
import Navigation from "@/mixins/Navigation.js"
import Shutter from "@/mixins/Shutter.js"
import ShutterNotes from "@/mixins/shutters-manager/Notes.js"

export default {
	name: 'PlanningAct',
    mixins: [Navigation, Shutter, ShutterNotes],
    props: {
        actesTypes: { type: Array, default: () => ([]) },
        actesTypesGyneco: { type: Array, default: () => ([]) },
        isReproTab: { type: Boolean, default: false },
		isPerfsTab: { type: Boolean, default: false },
        handleActesOperations: { type: Function, default: () => false },
       	startDate: { type: Date, default: null },
    },
    data() {
        return {
            /* Items and data */
			id_table: 'planning',
            column_previewable: ['horse_nom'],
			add_slot_cols: ['notes.type_fonts'],
			edit_slot_cols: ['notes.type_fonts'],

            /* Table config : */
            table_busy: true,
            config_table_hrefs: {
                'horse_nom': {
                    routeUniqueName: 'horseFiche',
                    routeName: 'horseFiche',
                    params: {
                        horse_id: 'actes_horse'
                    }
                },
				'entity_rs': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'entity_id'
					}
				}
            },
			dateFilter: {
				column: 'actes_date',
				start: null,
				end: null
			},

			reloadMethod: 'refreshTable'
        }
	},
	created() {
		if(this.$route.params.date) {
			const start = new Date(this.$route.params.date)
			start.setDate(start.getDate()-1)
			const end = new Date(this.$route.params.date)
			this.dateFilter.start = start
			this.dateFilter.end = end
			return false
		}

		if(this.startDate) {
			this.dateFilter.start = this.startDate
		}
		else if(this.getConfig('startDate' + this.id_table)) {
			this.dateFilter.start = new Date(this.getConfig('startDate' + this.id_table))
		}
		else {
			this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 3))
		}

		if(this.startDate) {
			this.dateFilter.end = null
		}
		else if(this.getConfig('endDate' + this.id_table)) {
			this.dateFilter.end = new Date(this.getConfig('endDate' + this.id_table))
		}
	},
	computed: {
		filters() {
			return {
				is_validated: {
					column: 'actes_actesstatut',
					operator: 'isEqualTo',
					value: 0
				},
				is_in_group: {
					column: 'actes_actestype',
					operator: 'isInList',
					value: this.actesTypes
				},
				is_not_archived: {
					column: 'horse_inactive',
					operator: 'isEqualTo',
					value: 0
				}
			}
		},
		filtersGyneco() {
			return {
				is_validated: {
					column: 'actes_actesstatut',
					operator: 'isEqualTo',
					value: 0
				},
				is_in_group: {
					column: 'actes_actestype',
					operator: 'isInList',
					value: this.actesTypesGyneco
				}
			}
		},
		hasSeasonAccess() {
			return this.$store.state.userAccess.hasBreedingAccess
				|| this.$store.state.userAccess.hasSaillieAccess
		},
		hasTrainerAccess() {
            return this.$store.state.userAccess.hasTrainerAccess
        },
	},
    methods: {
		refreshTable() {
			if(this.$refs.table) {
				this.$refs.table.refreshTable()
			}
			if(this.$refs.table_repro) {
				this.$refs.table_repro.refreshTable()
			}
			if(this.$refs.table_perf) {
				this.$refs.table_perf.refreshTable()
			}
		},

        /* Hook de clic sur une colonne de preview */
        catchColumnShutter(act, colName) {
            this.decideActionShutter(act, colName)
        },

		catchColumnNotesShutter(act) {
			this.handleNotePreview(act.notes.raw)
		},

        decideActionShutter(act, colName) {
            // S'il s'agit de la colonne de preview de base, on ouvre l'édition d'acte
            if(colName == 'default') {
                this.handleActesOperations({ state: 'modification', pk_id: act.actes_id.toString() })
            }
            // S'il s'agit de la colonne du cheval, on ouvre l'apperçu de la fiche jument
            else if(colName == 'horse_nom') {
                this.handleActesOperations({ state: 'horse_gyneco', pk_id: act.actes_horse.toString() })
            }
        }
    },
    components: {
        CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
    }
}
</script>
